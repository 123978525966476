import React from "react"
import API from "../services/api"
import * as styles from "../css/gamipress.module.css"

const Gamipress = () => {
  const getEarningsHandler = async () => {
    const { data } = await API.get("wp/v2/gamipress-user-earnings")
    console.log(data)
  }

  const createEarningsHandler = async () => {
    const postData = {
      title: "Earnings",
      user_id: 1,
      points: 100,
    }
    const { data } = await API.post("wp/v2/gamipress-user-earnings", postData)
    console.log(data)
  }
  return (
    <div className={styles.container}>
      <button onClick={getEarningsHandler}>Get Earnings</button>
      <button onClick={createEarningsHandler}>Create Earnings</button>
    </div>
  )
}

export default Gamipress
